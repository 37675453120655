<template>
  <div class="container-fluid">
    <div v-if="xlist.length" class="w-100 mt-5">
      <hr />
      <div class="row">
        <div class="col-sm-2">
          <h3 class="text-start">
            Loads <small>{{ xlistfilter.length }} of {{ xlist.length }}</small>
          </h3>
        </div>
        <div class="col-sm-10">
          <input
            v-model="searchload"
            type="text"
            class="form-control"
            placeholder="filter Shipment Info"
            @change="filterListLoads"
            @keyup="filterListLoads"
          />
        </div>
      </div>
      <div class="table-responsive table-responsive-sm">
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th v-for="i in 17" :key="i" scope="col">{{ list_th[i] }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in xlistfilter" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td v-for="i in 17" :key="i">
                <small>{{ item[i - 1] }}</small>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { simpleGet } from "../api/constants";
//import axios from "axios";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {},
  setup() {
    const list_th = ref([
      "",
      "Rate Amount",
      "Distance",
      "RPM",
      "KOF Rep Info",
      "Shipment Info",
      "Pick Date/Time",
      "Delivery Date/Time",
      "Equipment Type",
      "Rep Name/Extension/Load Notes",
      "Commodity",
      "Length",
      "Width",
      "Weight",
      "Height",
      "Post Status",
      "Equipment Options",
      "Load ID",
    ]);
    const searchload = ref();
    const xlist = ref([]);
    const xlistfilter = ref([]);

    onMounted(async () => {
      const xloads = await getXLoads();
      xlist.value = JSON.parse(xloads.data1);
      console.log(xlist.value);
      console.log("TH", list_th.value.length);
      filterListLoads();
    });
    const getXLoads = async () => {
      const list_xloads = await simpleGet("postedloads");
      return list_xloads.data;
    };
    const filterListLoads = () => {
      xlistfilter.value = xlist.value;
      if (searchload.value) {
        xlistfilter.value = xlist.value.filter((item, iter) =>
          item[4].toLowerCase().includes(searchload.value.toLowerCase())
        );
      }
      xlistfilter.value.sort(function (a, b) {
        var a = parseFloat(a[2].replace("$", ""));
        var b = parseFloat(b[2].replace("$", ""));
        return b - a;
      });
      /*xlistfilter.value.sort(function (a, b) {
        const dateA = new Date(a.dateend);
        const dateB = new Date(b.dateend);
        return dateA - dateB;
      });*/
      return xlistfilter.value;
    };
    return {
      list_th,
      searchload,
      xlist,
      xlistfilter,
      getXLoads,
      filterListLoads,
    };
  },
};
</script>
