/* eslint-disable prettier/prettier */
import axios from "axios";
export const API_URL = "https://spartan.app.3dboosterstudio.com/api/";

export const simpleGet = async (path) => {
  try {
    const data = await axios
      .get(API_URL + path)
      .then((data) => {
        return data.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const simplePost = async (path, form) => {
  try {
    const formData = new FormData();
    for (const i in form) {
      formData.append(i, form[i]);
    }
    const url = API_URL + path;
    const data = await axios
      .post(url, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((data) => {
        return data.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const drivers_notes = [
  "Roberto Ramirez: 919 630 4047 Truck: 5439 Trailer: 1901",
  "Elieser Perez: 305 570 5693 Truck: 1018 Trailer: 537050",
  "Amaury Arencibia: 786 564 6610 Truck: 6001 Trailer: 104",
  "Sandy Rodriguez: 210 584 5228 Truck: 7050 Trailer: 2121",
  "Reinaldo Maqueira: 305 967 5491 Truck: 1959 Trailer: 4616",
  "Jose Uria: 210 400 1943 Truck: 210 Trailer: 029",
  "Alder Gomez: 904 489 7067 Truck: 12 Trailer: 539047",
  "Sergio Garcia: 832 708 7050 Truck: 5034 Trailer: 11",
  "Edelberto Rodriguez: 813 527 1667 Truck: 17 Trailer: 50511",
];
export const loadStatus = [
  { value: "Pending", color: "warning" },
  { value: "In progress", color: "success" },
  { value: "Completed", color: "primary" },
  { value: "Late", color: "danger" },
  { value: "Cancelled", color: "danger" },
];
export const DepartTime = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];
export default API_URL;
